.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #26c7f3;
}

.clickCell:hover {
  background-color: rgba(107, 189, 221, 0.623);
  cursor: pointer;
}

.textField{
  height: 32px;
    width: 200px;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
  }
    .textField:hover {
      cursor: pointer;
    }
  
    .btnBuscar{
      height: 34px;
      width: 32px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  
    .barraBusqueda{
      float: right;
      padding: 20px;
    }

a{
  color: #26c7f3;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
